const imgs = {
    pageBgImg: '/images/mobile/app/appGreen/page-bg-img.png',
    downloadBtn: 'https://static.nowcoder.com/fe/file/oss/1733294136897LMNEL.png',
    swiperDiscuss: '/images/mobile/app/appGreen/swiper-discuss.png',
    swiperOffer: '/images/mobile/app/appGreen/swiper-offer.png',
    swiperPaper: '/images/mobile/app/appGreen/swiper-paper.png',
    swiperResume: '/images/mobile/app/appGreen/swiper-resume.png',
    swiperTopic: '/images/mobile/app/appGreen/swiper-topic.png'
};

const imgsStatic = {
    downloadBtn: 'https://static.nowcoder.com/fe/file/oss/1733294136897LMNEL.png',
    swiperDiscuss: 'https://static.nowcoder.com/fe/file/oss/1740380784760XFBHV.png',
    swiperOffer: 'https://static.nowcoder.com/fe/file/oss/1740380811834CAGDQ.png',
    swiperPaper: 'https://static.nowcoder.com/fe/file/oss/1740380841373UENOF.png',
    swiperResume: 'https://static.nowcoder.com/fe/file/oss/1740380882894BBVTL.png',
    swiperTopic: 'https://static.nowcoder.com/fe/file/oss/1740380905432XBMEA.png'
};

const slides = {
    type1: [
        {src: imgsStatic.swiperResume, name: '面试经验分享'},
        {src: imgsStatic.swiperPaper, name: '大厂笔面试真题'},
        {src: imgsStatic.swiperTopic, name: '海量练习题库'},
        {src: imgsStatic.swiperOffer, name: '名企招聘机会'},
        {src: imgsStatic.swiperDiscuss, name: '交流互助社区'}
    ],
    type2: [
        {src: imgsStatic.swiperPaper, name: '大厂笔面试真题'},
        {src: imgsStatic.swiperTopic, name: '海量练习题库'},
        {src: imgsStatic.swiperOffer, name: '名企招聘机会'},
        {src: imgsStatic.swiperDiscuss, name: '交流互助社区'},
        {src: imgsStatic.swiperResume, name: '面试经验分享'}
    ],
    type3: [
        {src: imgsStatic.swiperTopic, name: '海量练习题库'},
        {src: imgsStatic.swiperOffer, name: '名企招聘机会'},
        {src: imgsStatic.swiperDiscuss, name: '交流互助社区'},
        {src: imgsStatic.swiperResume, name: '面试经验分享'},
        {src: imgsStatic.swiperPaper, name: '大厂笔面试真题'}
    ],
    type4: [
        {src: imgsStatic.swiperOffer, name: '名企招聘机会'},
        {src: imgsStatic.swiperDiscuss, name: '交流互助社区'},
        {src: imgsStatic.swiperResume, name: '面试经验分享'},
        {src: imgsStatic.swiperPaper, name: '大厂笔面试真题'},
        {src: imgsStatic.swiperTopic, name: '海量练习题库'}
    ],
    type5: [
        {src: imgsStatic.swiperDiscuss, name: '交流互助社区'},
        {src: imgsStatic.swiperResume, name: '面试经验分享'},
        {src: imgsStatic.swiperPaper, name: '大厂笔面试真题'},
        {src: imgsStatic.swiperTopic, name: '海量练习题库'},
        {src: imgsStatic.swiperOffer, name: '名企招聘机会'}
    ]
};
export {imgs, slides};
