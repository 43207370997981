<template>
    <div class="page js-page">
        <img src="https://static.nowcoder.com/fe/file/oss/1740380551718AUFAU.png" alt="bg-img" />
        <div class="wrap">
            <div class="download-btn">
                <img :src="imgs.downloadBtn" alt="下载按钮" @click="actionDownload" />
            </div>
            <swiper class="swiper" :options="swiperOption" @slideChange="actionSlideChange">
                <swiper-slide v-for="item in slidesList" :key="item.name">
                    <img :src="item.src" alt="swiper" />
                </swiper-slide>
            </swiper>
        </div>
        <MaskBrowser v-if="isShowMask" :isShowMask.sync="isShowMask" />
    </div>
</template>
<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import App from '@/js/lib/util/app';
import Util from '@/js/lib/util/util';
import Uri from '@ncfe/nc.uri';
import Dom from '@ncfe/nc.dom';
import {GioApp} from '@/gio';
import ActivityAction from '@/js/lib/action/activity';
import MaskBrowser from '@/js/cpn/MaskBrowser.vue';
import {imgs, slides} from './constantData';
const num = Math.floor(Math.random() * 5 + 1);
const swiperType = `type${num}`;
const params = Uri.getParams();
const {gioValue, btnText, type, url, targetUrl} = params;
// 尽量找到fromPut
let fromPut = params.fromPut || '';
!fromPut && url && (fromPut = Uri.getParam('fromPut', url));
!fromPut && targetUrl && (fromPut = Uri.getParam('fromPut', targetUrl));
const pageEnter = gioValue === 'mobileyh1' ? '移动端讨论区优化迭代1' : fromPut || '无';
const uuid = Util.getUUID();
const pageName = '新页面';
@Component({components: {Swiper, SwiperSlide, MaskBrowser}})
export default class Index extends Vue {
    // 微信||企微||微博环境点击下载弹出提示浏览器打开的浮层。
    isCanDownLoad = !(App.isWx || App.isWxWork || App.isWeiBo);
    imgs = imgs;
    slidesList = slides[swiperType];
    swiperOption = {slidesPerView: 'auto'};
    isShowMask = false;
    currentSlideName = this.slidesList[0].name;
    exposureHeight = 0;
    mounted() {
        // 扫码进入
        const isScan = type === 'scan';
        const href = url || targetUrl;
        if (href && !App.isWxWork && !App.isZhiYe)
            App.openInApp({
                href,
                noMask: !isScan,
                noJump: true,
                callback: () => {
                    this.actionDownload();
                }
            });
        // 屏幕大小适配
        Util.setFontSize();
        // 离开页面埋点
        Util.pageViews(time => {
            GioApp.AppDownload_PageView({
                UniqueView_var: (window as any).globalInfo?.ownerId || '无',
                PageEnter_var: pageEnter,
                TimeView_var: time,
                Glide_var: this.getGlide(),
                PageName_var: `轮播顺序${num}`,
                PageOrder_var: this.currentSlideName
            });
        });
        //获取滚动高度
        $(window).on('scroll', oEvent => {
            const win = $(window);
            const height = win?.height?.() || 0;
            const num = height + (win?.scrollTop?.() || 0);
            this.exposureHeight < num && (this.exposureHeight = num);
        });
    }
    actionSlideChange(e: {activeIndex: any}) {
        this.currentSlideName = this.slidesList[e?.activeIndex || 0]?.name;
    }
    // 获取下载链接
    get downloadUrl() {
        const params = `${fromPut ? `fromPut=${fromPut}&` : ''}${btnText ? `nowcoderPut=${pageName}_${btnText}` : ''}`;
        const currentEnv = App.isWx ? 'yybUrl' : App.isIOS ? 'iOSUrl' : 'apkUrl';
        let loadUrl = `${(window as any).pageInfo?.[currentEnv]}`;
        // 知页app  iOS端要以 https 作为前缀
        if (currentEnv === 'iOSUrl' && App.isZhiYe) {
            loadUrl = `https://${(window as any).pageInfo?.[currentEnv].split('://')[1]}`;
        }
        // 换个链接，pageINfo的链接会唤起浏览器的弹窗，不会直接跳转AppStore
        if (currentEnv === 'iOSUrl' && !App.isZhiYe) {
            loadUrl = (window as any).ncAppDownloadUrl;
        }
        // 拼接来源参数
        loadUrl += params && (App.isWx ? '&' : '?') + params;
        return loadUrl;
    }
    async actionDownload() {
        const {isCanDownLoad, downloadUrl} = this;
        if (!isCanDownLoad) {
            this.isShowMask = true;
            return;
        }
        Dom.copyContent(uuid); // 复制uuid
        // 埋点 ，点击下载按钮埋点
        GioApp.ClickDownload({
            downloadEnter_var: pageEnter,
            PageName_var: pageName,
            PageOrder_var: this.currentSlideName
        });
        // 下载页归因
        await ActivityAction.getDownloadAppInfo({
            query: {
                url,
                nowcoderPut: pageName,
                fromPut: pageEnter,
                h5ClipboardId: uuid
            }
        }).catch(() => {});
        window.location.href = downloadUrl;
    }
    // 计算下滑率
    getGlide() {
        const num = (this.exposureHeight / ($('.js-page') as any)?.height?.()) * 100;
        if (num <= 25) {
            return '0-25%';
        } else if (num <= 50) {
            return '26%-50%';
        } else if (num <= 75) {
            return '51-75%';
        } else {
            return '76%-100%';
        }
    }
}
</script>
<style lang="scss" scope>
body {
    background-color: rgba(247, 253, 252, 1);
}
.page {
    max-width: 450px;
    margin: 0 auto;
    width: 100%;
    position: relative;

    &::before {
        content: '';
        display: table;
    }
    img {
        width: 100%;
        vertical-align: middle;
    }
    .wrap {
        position: absolute;
        left: 0;
        top: 0;

        .download-btn {
            display: block;
            width: 329px;
            background: none;
            padding: 0;
            /**
             * 使用媒体查询调整 margin
             * 450px 大屏幕
             * 400px 中屏幕
             * 360px 小屏幕
             */
            @media screen and (min-width: 450px) {
                margin: 253px auto 110px; // 大屏幕时的 margin
            }

            @media screen and (max-width: 449px) and (min-width: 400px) {
                margin: 243px auto 110px;
            }
            @media screen and (max-width: 399px) and (min-width: 361px) {
                margin: 213px auto 110px;
            }
            @media screen and (max-width: 360px) {
                margin: 193px auto 110px; // 小于360
            }
        }

        .swiper {
            .swiper-slide {
                width: 73.3333%;
                margin-left: 10px;
                margin-right: 8px;
            }
        }
    }
}
</style>
